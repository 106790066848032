@use '../../styles/mixins' as mixins;
@use '../../styles/breakpoints' as bp;

.team-block {

	@include mixins.sectionStyles;
	
	.bnpp-container {
		display: flex;
		gap: var(--wp--preset--spacing--60);
		flex-wrap: wrap;

		@media only screen and (max-width: bp.$breakpoint-tiny) {
			gap: 0;
		}

		.team-block__content {
			flex: 0 1 33%;

			@media only screen and (max-width: bp.$breakpoint-small) {
				flex: 1;
			}

			+ div {
				width: 100%;
				flex: 1 1 400px;
			}
		}

		.team-block__team_list {
			flex: 1 1 400px;
		}
	}
	
}